import React from "react";
import Particles from "react-particles-js";
import Socialicons from "../components/Socialicons";
import Layout from "../components/Layout";

function Home(){
  const paramConfig = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false
        }
      },
      color: {
        value: "#fab940"
      },
      opacity: {
        value: 0.1
      },
      size: {
        value: 15,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3
        }
      },
      line_linked: {
        enable: false
      },
      move: {
        random: true,
        speed: 1,
        direction: "top",
        out_mode: "out"
      }
    }
  };

  return (
    <Layout>
      <div className="mi-home-area mi-padding-section">
        <Particles className="mi-home-particle" params={paramConfig} />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="mi-home-content">
                <h1> 
                  <span className="color-theme"> 
                    <a href='https://venicepizzeria.hungerrush.com/Order/Menu/1#groups'> Order Now </a> 
                  </span>
                </h1>
                  <p style={{fontSize: "35px", color: "white", fontWeight: "600"}}>
                    Our the Best Pizzas
                  </p>
                <Socialicons bordered />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Home;
